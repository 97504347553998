import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
// import { composeWithDevTools } from 'redux-devtools-extension/logOnly';
import CssBaseline from '@mui/material/CssBaseline';
import { HashRouter } from 'react-router-dom';
import { createReduxHistoryContext } from "redux-first-history";
import { createHashHistory } from 'history';
// import { createBrowserHistory } from 'history';
import promiseFinally from 'promise.prototype.finally';
import { NotificationContainer } from 'react-notifications';
import jwtDecode from 'jwt-decode';
import 'react-notifications/lib/notifications.css';
import 'flag-icons/css/flag-icons.min.css';
import './index.css';
import App from './App';
import reducers from './modules';
import middlewares from './middlewares';
import * as settings from './modules/settings';
import * as login from './modules/login';
import * as decision from './modules/decision';
import * as resetPassword from './modules/reset-password';
import * as rest from './modules/rest';
import { gtFetch } from './modules/rest';
import { getSiteLink } from './lib/util';
import { createMSALInstance, getMSALConfig, msalInstance, getLoginRequest } from './msal-config';
import { isMobile } from 'react-device-detect';

promiseFinally.shim();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ 
  history: createHashHistory(),
  //other options if needed 
});

const composeEnhancers =
  typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      //  actionSanitizer: action =>
      //    (action.type === PASSWORD_SET ? { ...action, password: '***' } : action),
      stateSanitizer: state => ({ ...state, login: { ...state.login, password: '***' } }),
    }) : compose;

const createRootReducer = history => combineReducers({
  router: routerReducer,
  ...reducers,
  routerReducer,
});

const DEFAULT_SETTINGS = {
  logo: '/config/logo.png',
  timeout: 10 * 60, // 10 minutes in seconds
  enableSignup: false,
  enablePasswordRenewal: true,
};

const middleware = applyMiddleware(thunk, ...middlewares, routerMiddleware);
const store = createStore(
  createRootReducer(history),
  composeEnhancers(middleware),
);

const history = createReduxHistory(store);

function fetchSettings() {
  return Promise.resolve(Object.assign({}, DEFAULT_SETTINGS, window.config));
}

function parseParams() {
  // eslint-disable-next-line no-underscore-dangle
  const isConsent = window.location.hash.startsWith('#/consent');
  const properQuery = window.location.search.substring(1)
  const hashQuery = new URL(window.location.hash.substring(1), window.location).search.substring(1);

  const query = new URLSearchParams(properQuery ? properQuery : hashQuery);

  const authCode = query.get('authCode');
  const authCodeUuid = query.get('authCodeUuid');
  const challenge = query.get('challenge');
  const email = query.get('email');
  const phone = query.get('phone');
  const scope = query.getAll('scope');
  const strict = query.get('strict');
  const token = query.get('token');
  const domainSelect = query.get('domain_select');
  const onetimeCode = query.get('onetime-code') || query.get('onetime_code');
  const redirectUri = query.get('redirect_uri');
  const mode = query.get('mode');
  const noRemember = query.get('no_remember');
  const noLang = query.get('no_lang');


  // If no {login,domain}_hint present, load from localStorage
  const domain_hint = query.get('domain_hint');
  const login_hint = query.get('login_hint');
  const tenant_id = query.get('tenant_id');
  const azureAd = query.get('azure-ad') !== null
    || tenant_id
    || domain_hint
    || login_hint
    || (mode === 'external' && strict !== null);


  if (domain_hint) {
    getLoginRequest().extraQueryParameters = { domain_hint }
  }

  if (login_hint) {
    getLoginRequest().loginHint = login_hint;
  }

  if (tenant_id) {
    getMSALConfig().auth.authority = `https://login.microsoftonline.com/${tenant_id}`;
  }

  // remove Azure-AD from query
  query.delete('azure-ad');
  query.delete('mode');
  query.delete('strict');
  window.history.replaceState(null, 'login', `${window.location.origin}${window.location.pathname}?${query.toString()}${window.location.hash}`);

  if (scope) {
    const scopeArr = typeof scope === typeof '' ? [scope] : scope;
    scopeArr.forEach(s => store.dispatch(decision.setScope(s, true)));
  }

  // 'strict' means login mode is restricted to what the login parameters
  // dictate, e.g. email login if 'email' is set, etc. Also the corresponding
  // input field is marked read only
  store.dispatch(settings.setStrict(strict !== null));

  // This must be set before challenge is set, so we know what kind of challenge it is
  store.dispatch(rest.setConsent(isConsent));

  store.dispatch(settings.setPhone(phone || ''));
  store.dispatch(settings.setEmail(email || ''));
  store.dispatch(settings.setAzureAD(settings.getAzureAD(store.getState()) || azureAd));
  store.dispatch(settings.setOnetimeCode(onetimeCode || null));
  store.dispatch(settings.setRedirectUri(redirectUri));
  store.dispatch(settings.setDomainSelect(domainSelect));
  store.dispatch(settings.setNoRemember(noRemember !== null));
  store.dispatch(settings.setShowLang(noLang === null));
  

  // Fetch active sessions
  store.dispatch(rest.getSessions());
  
  // Only set login-mode if specified through query params via phone/email
  if (phone) {
    store.dispatch(login.setLoginMode('sms'));
  } else if (email) {
    store.dispatch(login.setLoginMode('email'));
  } else if (azureAd || login_hint || domain_hint) {
    store.dispatch(login.setLoginMode('external'));
  }
  // ...or specifically requested
  if (mode && ['sms', 'email', 'password', 'otp', 'external'].indexOf(mode) !== -1) {
    store.dispatch(login.setLoginMode(mode));
  }

  // On mobile phones, login modes are handled a bit differently than on other devices, where we show all
  // login modes in a tabbed view. On phones, they're separate URLs. Adjust the URL when mode is strict
  if (isMobile && settings.getStrict(store.getState())) {
    document.location.hash = `${document.location.hash}/${login.getLoginMode(store.getState())}`;
  }

  if (authCode && authCodeUuid) {
    // If authcode specified we came here from link. It is very probable that it was from email.
    store.dispatch(login.setLoginMode('email'));
    store.dispatch(settings.setAuthCode(authCode));
    store.dispatch(rest.setAuthCodeUuid(authCodeUuid));
  }

  if (challenge) {
    store.dispatch(rest.setChallenge(challenge));
  }

  if (token) {
    store.dispatch(resetPassword.setResetToken(token));
  }
  return query;
}

const msalPromise = fetchSettings().then((set) => {
    store.dispatch(settings.setLogo(set.logo));
    //    store.dispatch(settings.setTimeout(set.timeout));
    store.dispatch(settings.setEnableSignup(set.enableSignup));
    store.dispatch(settings.setPasswordRenewal(set.enablePasswordRenewal))
    parseParams();
    return createMSALInstance();
});

function runApp() {
  msalPromise.then((msal) => {
    if (settings.getAzureAD(store.getState()) && !login.isADAccountNotInGT(store.getState())) {
      // If we're requested to do AD authentication, go there directly
      msal.loginRedirect(getLoginRequest());
    } else {
      ReactDOM.render(
        <Suspense fallback={<></>}>
          <React.Fragment>
            <CssBaseline />
            <Provider store={store}>
              <HashRouter>
                <App />
              </HashRouter>
            </Provider>
            <NotificationContainer/>
          </React.Fragment>
        </Suspense>,
        document.getElementById('root'),
      );
    }
  });
}

function checkUserNotFound(err) {
  const errData = err.json ? err.json.error : (err.errorCode ? err.errorCode : err);

  // If user wasn't found
  if (errData === 'user.not.found') {
    if (err.json.payload && err.json.payload.idToken) {
      try {
        const idToken = jwtDecode(err.json.payload.idToken);
        store.dispatch(login.setADAccount(idToken.preferred_username));
      } catch (e) {
        // Ignore
      }      
    }
    store.dispatch(login.setADAccountNotInGT(true));
    store.dispatch(settings.setAzureAD(true));

    // Redo AD authentication, but now prompt for which account to use
    getLoginRequest().prompt = 'select_account';
  }
}

msalPromise
  .then(msal => msal.handleRedirectPromise())
  .then((resp) => {
    if (resp !== null) {
      // We've acquired our token!
      // We don't have access to the state here
      const challenge = parseParams().get('challenge');
      const remember = settings.getStoredRemember();
      store.dispatch(login.setLoginMode('external'));

      // Send it to the authentication backend, to finalize the login, 
      // and redirect to the URL that's returned
      return gtFetch(`${getSiteLink('openid')}/authentication/api/auth/login/azure-ad`, {
        method: 'POST',
        body: JSON.stringify({
          challenge,
          accessToken: resp.accessToken,
          idToken: resp.idToken,
          refreshToken: resp.refreshToken,
          remember,
        })
      }).then((response) => {
        if (response.json && response.json.redirectTo) {
          store.dispatch(login.redirecting());
          window.setTimeout(() => {
            window.location.href = response.json.redirectTo;
          }, 100);
        } else {
          return Promise.reject(`Unexpected response: ${response}`);
        }
      }).catch((response) => {
        store.dispatch(rest.authenticateFailure(response));
        checkUserNotFound(response);
        if (response.json) {
          store.dispatch(error.flagError(response.json));
        } else {
          return Promise.reject(response);
        }
      });
    }
  })
  .then(() => {
    store.dispatch(login.initCompleted());
  })
  .catch((err) => {
    checkUserNotFound(err);

    const errData = err.json ? err.json.error : (err.errorCode ? err.errorCode : err);

    // msal may have rewritten the location, but react will not notice.
    // We need to wait for msal to actually rewrite location back to original
    window.setTimeout(() => {
      const challenge = parseParams().get('challenge');
      store.dispatch(login.setLoginMode('external'));
      store.dispatch(login.setError(errData));
      if (challenge) {
        // store.dispatch(replace(`/login?challenge=${challenge}`));
      }
      store.dispatch(login.initCompleted());
    }, 100);
  })
  .finally(() => {
    // Seems we need to do this before rewriting URL.
    // To avoid showing GUI with bad URL we do not show anything until the initCompleted state is reached
    runApp();
  });
