import React from 'react';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { MSLogin } from '../../icons';
import { msalInstance, getLoginRequest } from '../../msal-config';
import AuthenticateBase from './authenticate-base';
import * as settings from '../../modules/settings';
import * as login from '../../modules/login';
import { useTranslation } from 'react-i18next';

function AuthenticateExternals({ adAccount, isADAccountNotInGT, onSubmit, remember, setRemember, noRemember }) {
  const { t } = useTranslation();
  const [ disabled, setDisabled ] = React.useState(false);

  const doSubmit = (data) => {
    setDisabled(true);
    onSubmit(data);
    if (isADAccountNotInGT) {
      getLoginRequest().prompt = 'select_account';
    }
    window.setTimeout(() => msalInstance.loginRedirect(getLoginRequest()), 10);
  };
  
  let error = <></>;
  if (isADAccountNotInGT) {
    error = <Alert severity='error'>
      { adAccount && <span><span className="account-name">{ adAccount }</span> { t('is not a GroupTalk account') }.</span> }
      <span>{ t('Try again to select alternate account') }</span>
    </Alert>;
  }
  return <AuthenticateBase subtitle={(domain) => domain}>
    <Form onSubmit={doSubmit} initialValues={{ remember }} render={({ handleSubmit }) => (
      <form
        className="flex"
        name="externalForm"
        onSubmit={handleSubmit}
      >
        <div className="layout-row">
          <div className="flex-20" />
          <IconButton
            disabled={disabled}
            type="submit"
            disableRipple
            variant="contained"
            color="primary"
            className="raisedPrimary flex-60"
            style={{ margin: '6px 0px' }}
          >
            <MSLogin viewBox="0 0 104 41" style={{ width: '104px', height: '41px' }} />
          </IconButton>
          <div className="flex-20" />
        </div>
        { error }
        { noRemember || <FormControlLabel control={<Checkbox checked={remember} onChange={({target}) => setRemember(target.checked)} />} label={t('Remember me')} /> }
      </form>
    )} />
    </AuthenticateBase>;
}

export default connect(
  state => ({
    adAccount: login.getADAccount(state),
    isADAccountNotInGT: login.isADAccountNotInGT(state),
    remember: settings.getRemember(state),
    noRemember: settings.getNoRemember(state),
  }),
  {
    setRemember: settings.setRemember,
  }
)(AuthenticateExternals);
