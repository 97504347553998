import { handleAction, handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getSiteLink } from '../lib/util';
import * as settings from './settings';
import * as login from './login';

// Action Types
const MODULE = 'rest';

export const SET_CONSENT = `${MODULE} : Set consent`
export const SET_CHALLENGE = `${MODULE} : Set challenge`;
export const SET_AUTH_CODE_UUID = `${MODULE} : Set auth code UUID`;
export const ABORT_OOB = `${MODULE} : OOB aborted`;
export const VALIDATE_CHALLENGE = `${MODULE} : Validate challenge`;
export const VALIDATE_CHALLENGE_SUCCESS = `${MODULE} : Validate challenge success`;
export const VALIDATE_CHALLENGE_FAILURE = `${MODULE} : Validate challenge failure`;
export const AUTHENTICATE_REQUEST = `${MODULE} : Authenticate request`;
export const AUTHENTICATE_REQUEST_FOR_OOB = `${MODULE} : Authenticate request for OOB`;
export const AUTHENTICATE_OOB_REQUEST = `${MODULE} : Authenticate OOB request`;
export const AUTHENTICATE_SUCCESS = `${MODULE} : Authenticate success`;
export const AUTHENTICATE_FAILURE = `${MODULE} : Authenticate failed`;
export const AUTHENTICATE_CANCEL_REQUEST = `${MODULE} : Authenticate cancel request`;
export const AUTHENTICATE_CANCEL_SUCCESS = `${MODULE} : Authenticate cancel success`;
export const AUTHENTICATE_CANCEL_FAILURE = `${MODULE} : Authenticate cancel failed`;
export const VERIFY_TOKEN_REQUEST = `${MODULE} : Verify token request`;
const PROGRESS_SHOW = `${MODULE} : Show progress`;
const PROGRESS_HIDE = `${MODULE} : Hide progress`;
export const VALIDATE_RESET_SUCCESS = `${MODULE} : Validate reset success`;
export const VALIDATE_RESET_FAILURE = `${MODULE} : Validate reset failure`;
export const VALIDATE_PASSWD_SUCCESS = `${MODULE} : Validate password success`;
export const VALIDATE_PASSWD_FAILURE = `${MODULE} : Validate password failure`;
export const RESET_PASSWD_SUCCESS = `${MODULE} : Reset password success`;
export const RESET_PASSWD_FAILURE = `${MODULE} : Reset password failure`;
export const EMAIL_RESET_SUCCESS = `${MODULE} : Email reset success`;
export const EMAIL_RESET_FAILURE = `${MODULE} : Email reset failure`;
export const EMAIL_RESET_RESET = `${MODULE} : Email reset reset`;
export const CONSENT_REQUEST = `${MODULE} : Consent request`;
export const CONSENT_SUCCESS = `${MODULE} : Consent success`;
export const CONSENT_FAILURE = `${MODULE} : Consent failed`;
export const CONSENT_REJECT_REQUEST = `${MODULE} : Consent reject request`;
export const CONSENT_REJECT_SUCCESS = `${MODULE} : Consent reject success`;
export const CONSENT_REJECT_FAILURE = `${MODULE} : Consent reject failed`;
export const ACQUIRE_OTP_REQUEST = `${MODULE} : Acquire OTP request`;
export const ACQUIRE_OTP_SUCCESS = `${MODULE} : Acquire OTP success`;
export const ACQUIRE_OTP_FAILURE = `${MODULE} : Acquire OTP failed`;
export const SET_DOMAIN_FAILURE = `${MODULE} : Set domain failure`;
export const REDIRECT_TO = `${MODULE} : Redirect`;
export const SET_OTP_RETRY_TIMER = `${MODULE} : Set OTP retry timer`;

// Selectors
export const isConsent = state => state[MODULE].consent;
export const isAwaitingOOBData = state => state[MODULE].awaitingOOBData;
export const isFetching = state => state[MODULE].progress;
export const processingConsent = state => state[MODULE].processingConsent;
export const getAuthCodeUuid = state => state[MODULE].authCodeUuid;
export const getChallenge = state => state[MODULE].challenge;
export const getOTPRetryTimer = state => state[MODULE].otpRetryTimer;

const createMsg = (type, payload) => ({
  type,
  ...payload,
});

// Actions
export const setConsent = payload => createMsg(SET_CONSENT, { payload });
export const setChallenge = challenge => ({ type: SET_CHALLENGE, challenge });
export const authenticateRequest = () => ({ type: AUTHENTICATE_REQUEST });
export const authenticateCancelRequest = () => ({ type: AUTHENTICATE_CANCEL_REQUEST });
export const setAuthCodeUuid = authCodeUuid => ({ type: SET_AUTH_CODE_UUID, authCodeUuid });
export const verifyTokenRequest = () => ({ type: VERIFY_TOKEN_REQUEST });
export const oobAborted = () => ({ type: ABORT_OOB });
// Handle progress only within this module
const showProgress = payload => createMsg(PROGRESS_SHOW, { payload });
const hideProgress = () => ({ type: PROGRESS_HIDE });

export const validateChallengeSuccess = payload => createMsg(VALIDATE_CHALLENGE_SUCCESS, { payload });
export const validateChallengeFailure = payload => createMsg(VALIDATE_CHALLENGE_FAILURE, { payload });
export const authenticateRequestForOOB = payload => createMsg(AUTHENTICATE_REQUEST_FOR_OOB, { payload });
export const authenticateSuccess = payload => createMsg(AUTHENTICATE_SUCCESS, { payload });
export const authenticateFailure = payload => createMsg(AUTHENTICATE_FAILURE, { payload });
export const authenticateCancelSuccess = payload => createMsg(AUTHENTICATE_CANCEL_SUCCESS, { payload });
export const authenticateCancelFailure = payload => createMsg(AUTHENTICATE_CANCEL_FAILURE, { payload });
export const emailResetReset = () => createMsg(EMAIL_RESET_RESET, {});
export const emailResetSuccess = () => createMsg(EMAIL_RESET_SUCCESS, {});
export const emailResetFailure = payload => createMsg(EMAIL_RESET_FAILURE, { payload });
export const validatePasswordSuccess = payload => createMsg(VALIDATE_PASSWD_SUCCESS, { payload });
export const validatePasswordFailure = payload => createMsg(VALIDATE_PASSWD_FAILURE, { payload });
export const resetPasswordSuccess = payload => createMsg(RESET_PASSWD_SUCCESS, { payload });
export const resetPasswordFailure = payload => createMsg(RESET_PASSWD_FAILURE, { payload });
export const requestConsent = grantedScopes => createMsg(CONSENT_REQUEST, { grantedScopes });
export const consentSuccess = payload => createMsg(CONSENT_SUCCESS, { payload });
export const consentFailure = payload => createMsg(CONSENT_FAILURE, { payload });
export const requestConsentReject = () => ({ type: CONSENT_REJECT_REQUEST });
export const consentRejectSuccess = payload => createMsg(CONSENT_REJECT_SUCCESS, { payload });
export const consentRejectFailure = payload => createMsg(CONSENT_REJECT_FAILURE, { payload });
export const requestOTP = payload => createMsg(ACQUIRE_OTP_REQUEST, { payload });
export const acquireOTPSuccess = payload => createMsg(ACQUIRE_OTP_SUCCESS, { payload });
export const acquireOTPFailure = payload => createMsg(ACQUIRE_OTP_FAILURE, { payload });
export const setDomainFailure = payload => createMsg(SET_DOMAIN_FAILURE, { payload });
export const setRedirectTo = payload => createMsg(REDIRECT_TO, { payload });
export const setOTPRetryTimer = payload => createMsg(SET_OTP_RETRY_TIMER, { payload });

const DEFAULT_FETCH_OPTS = {
  mode: 'cors',
  credentials: 'include',
  redirect: 'follow',
  headers: {
    'Accept': 'application/json,text/plain',
//    'Content-Type': 'application/json',
  },
  timeout: 5000,
};

export function gtFetch(url, opts = {}) {
  const mergeOpts = Object.assign({}, DEFAULT_FETCH_OPTS, opts);
  if (opts.body) {
    mergeOpts.headers['Content-Type'] = 'application/json';
  }
  return fetch(url, mergeOpts).then((response) => {
    const contentType = response.headers.get('Content-Type');
    let result;
    if (contentType && contentType.includes('application/json')) {
      result = response.json().then(json => ({ json, response }));
    } else if (contentType && contentType.includes('text/plain')) {
      result = response.text().then(text => ({ text, response }));
    } else {
      result = Promise.resolve({ response });
    }
    return result.then(res => (res.response.ok ? res : Promise.reject(res)));
  });
}

function toQuery(params) {
  const esc = encodeURIComponent;
  return Object.keys(params)
    .map(k => `${esc(k)}=${esc(params[k])}`)
    .join('&');
}

export function withProgress(payload, fun, success, error) {
  return (dispatch, getState) => {
    dispatch(showProgress(payload));
    return fun(dispatch, getState).finally(() => {
      dispatch(hideProgress());
      if (login.getError(getState())) {
        dispatch(login.resetError());
      }
    }).then(success({dispatch, getState}), error({dispatch, getState}));
  };
}

export function restRequest(progressOptions, params) {
  return withProgress(progressOptions, (dispatch, getState) => {
    const { url, options, preDispatch } = typeof params !== 'function' ? params : params(getState());
    if (preDispatch) {
      preDispatch(dispatch);
    }
    return gtFetch(url, options);
  },
    ({dispatch, getState}) => {
      const { successDispatch } = typeof params !== 'function' ? params : params(getState());
      return data => successDispatch(dispatch, data);
    },
    ({dispatch, getState}) => {
      const { failDispatch } = typeof params !== 'function' ? params : params(getState());
      return error => failDispatch(dispatch, error);
    },
  );
}

export const abortOOB = () => (dispatch) => {
  dispatch(authenticateFailure('User aborted'));
  dispatch(oobAborted());
};

export const resendOOB = ({ mode, email, phone, webOtp, options = {} }) => {
  return withProgress({}, (dispatch, getState) => {
    const body = JSON.stringify({
      email,
      phone,
      webOtp,
      authCodeUuid: getAuthCodeUuid(getState()), 
      challenge: getChallenge(getState()), 
    });
    return gtFetch(
      `${getSiteLink('openid')}/authentication/api/auth/login/${mode}-resend`,
      { ...options, method: 'POST', body },
    )
  },
      ({dispatch}) => data => dispatch(authenticateRequestForOOB(data)),
      ({dispatch}) => error =>  {
        const payload = { body, mode };
        if (error.json) {
          payload.json = error.json;
        } else {
          payload.error = error;
        }
        dispatch(authenticateFailure(payload));
        return null;
      },
    );
};

export const authenticate = ({
  mode, oob, body, options = {},
}) =>
  restRequest(options, state => {
    const remember = oob ? {} : { remember: settings.getRememberAsEnum(state) };
    const data = { ...body, challenge: getChallenge(state), ...remember };
    return {
      url: `${getSiteLink('openid')}/authentication/api/auth/login/${mode}`,
      options: { ...options, redirect: 'manual', method: 'POST', body: JSON.stringify(data), },
      preDispatch: dispatch => dispatch(authenticateRequest()),
      successDispatch: (dispatch, data) => {
        if (oob) {
          dispatch(authenticateRequestForOOB(data));
        } else {
          dispatch(authenticateSuccess(data));
        }
        return data;
      },
      failDispatch: (dispatch, error) => {
        const payload = { body, mode };
        if (error.json) {
          payload.json = error.json;
        } else {
          payload.error = error;
        }
        dispatch(authenticateFailure(payload));
        return null;
      },
    };
  });

export const validateChallenge = ({
  challenge,
  options = {},
}) => restRequest(options, state => {
  const path = isConsent(state)
    ? 'consent'
    : 'login';
  return {
    url: `${getSiteLink('openid')}/authentication/api/auth/${path}/validate?challenge=${challenge}`,
    options: { ...options, redirect: 'manual', method: 'GET', },
    successDispatch: (dispatch, data) => {
      dispatch(validateChallengeSuccess(data));
      return data;
    },
    failDispatch: (dispatch, error) => {
      dispatch(validateChallengeFailure(error));
      return null;
    }
  };
});

export const rejectAuth = ({ error, error_description, options = {} }) =>
  restRequest(options, state => ({
    url: `${getSiteLink('openid')}/authentication/api/auth/login/cancel`,
    options: { ...options, redirect: 'manual', method: 'POST', body: JSON.stringify({
      challenge: getChallenge(state),
      error,
      error_description,
    })},
    preDispatch: dispatch => dispatch(authenticateCancelRequest()),
    successDispatch: (dispatch, data) => {
      dispatch(authenticateCancelSuccess(data));
      return data;
    },
    failDispatch: (dispatch, error) => {
      const payload = { body: getChallenge(state) };
      if (error.json) {
        payload.json = error.json;
      } else {
        payload.error = error;
      }
      dispatch(authenticateCancelFailure(payload));
      return null;
    },
  }));

export const verifyToken = ({ code, options = {} }) =>
  restRequest(options, state => {
    const body = JSON.stringify({
      code,
      authCodeUuid: getAuthCodeUuid(state),
      challenge: getChallenge(state),
      remember: settings.getRememberAsEnum(state),
    });
    return {
      url: `${getSiteLink('openid')}/authentication/api/auth/login/code`,
      options: { ...options, method: 'POST', body },
      preDispatch: dispatch => dispatch(verifyTokenRequest()),
      successDispatch: (dispatch, data) => {
        dispatch(authenticateSuccess(data));
        return data;
      },
      failDispatch: (dispatch, error) => {
        const payload = { body };
        if (error.json) {
          payload.json = error.json;
        } else {
          payload.error = error;
        }
        dispatch(authenticateFailure(payload));
        return null;
      },
    };
  });

export const emailResetCode = ({
  body, success, fail, options = {},
}) =>
  restRequest({}, {
    url: `${getSiteLink('openid')}/authentication/api/auth/login/reset-token`,
    options: { ...options, method: 'POST', body: JSON.stringify(body) },
    successDispatch: dispatch => success(dispatch),
    failDispatch: (dispatch, error) => fail(dispatch, error),
  });

export const validateReset = ({
  token, success, fail, options = {},
}) =>
  restRequest({}, {
    url: `${getSiteLink('openid')}/authentication/api/auth/login/validate-reset?${toQuery({ token })}`,
    options,
    successDispatch: (dispatch, data) => success(dispatch, data),
    failDispatch: (dispatch, error) => fail(dispatch, error),
  });

export const validatePassword = ({ password, token }) =>
  withProgress({}, (dispatch, getState) =>
    gtFetch(
      `${getSiteLink('openid')}/authentication/api/auth/login/validate-password`,
      { method: 'POST', body: JSON.stringify({ password, token }) },
    ), () => null, () => null);

export const resetPassword = ({
  password, token, success, fail, options = {},
}) =>
  restRequest({}, {
    url: `${getSiteLink('openid')}/authentication/api/auth/login/reset-password`,
    options: { ...options, method: 'POST', body: JSON.stringify({ password, token }) },
    successDispatch: dispatch => success(dispatch),
    failDispatch: (dispatch, error) => fail(dispatch, error),
  });

export const consent = ({ grantedScopes, options }) =>
  restRequest(options, state => ({
    url: `${getSiteLink('openid')}/authentication/api/auth/consent`,
    options: { ...options, method: 'POST', body: JSON.stringify({ grantedScopes, challenge: getChallenge(state) }) },
    successDispatch: (dispatch, data) => dispatch(consentSuccess(data.json)),
    failDispatch: (dispatch, error) => dispatch(consentFailure(error)),
  }));

export const consentReject = ({ options }) =>
  restRequest(options, state => ({
    url: `${getSiteLink('openid')}/authentication/api/auth/consent/cancel`,
    options: { ...options, method: 'POST', body: JSON.stringify({ challenge: getChallenge(state) }) },
    successDispatch: (dispatch, data) => dispatch(consentRejectSuccess(data.json)),
    failDispatch: (dispatch, error) => dispatch(consentRejectFailure(error)),
  }));

export const acquireOTP = ({ options }) =>
  restRequest(options, {
    url: `${getSiteLink('openid')}/authentication/api/auth/onetime-token`,
    options: { ...options, method: 'GET' },
    successDispatch: (dispatch, data) => dispatch(acquireOTPSuccess(data.json)),
    failDispatch: (dispatch, error) => dispatch(acquireOTPFailure(error)),
  });

export const setDomain = ({ domain, options }) =>
  restRequest(options, state => ({
    url: `${getSiteLink('openid')}/authentication/api/auth/change-domain`,
    options: { ...options, method: 'POST', body: JSON.stringify({ challenge: getChallenge(state), domain: domain }) },
    successDispatch: (dispatch, data) => dispatch(setRedirectTo(data.json.redirectTo)),
    failDispatch: (dispatch, error) => dispatch(setDomainFailure(error)),
  }));

export const getSessions = () =>
  restRequest({}, state => ({
    url: `${getSiteLink('openid')}/authentication/api/auth/sessions`,
    options: { method: 'GET' },
    successDispatch: (dispatch, data) => dispatch(login.setSessions(data.json)),
    failDispatch: (dispatch, error) => () => 0,
  }));

export const setSession = () =>
  restRequest({}, state => ({
    url: `${getSiteLink('openid')}/authentication/api/auth/sessions`,
    options: { method: 'GET' },
    successDispatch: (dispatch, data) => dispatch(login.setSessions(data.json)),
    failDispatch: (dispatch, error) => () => 0,
  }));

// Reducer
export default {
  [MODULE]: combineReducers({
    consent: handleAction(SET_CONSENT, (_, action) => action.payload, false),
    progress: handleActions(
      {
        [PROGRESS_SHOW]: (_, action) => action.payload,
        [PROGRESS_HIDE]: () => false,
      },
      false,
    ),
    awaitingOOBData: handleActions(
      {
        [AUTHENTICATE_REQUEST_FOR_OOB]: () => true,
        [AUTHENTICATE_SUCCESS]: () => false,
        [ABORT_OOB]: () => false,
        [SET_AUTH_CODE_UUID]: () => true,
      },
      false,
    ),
    emailedReset: handleActions(
      {
        [EMAIL_RESET_SUCCESS]: () => true,
        [EMAIL_RESET_FAILURE]: () => false,
        [EMAIL_RESET_RESET]: () => null,
      },
      null, /* We haven't sent a password reset email, so neither true nor false */
    ),
    processingConsent: handleActions(
      {
        [CONSENT_REQUEST]: () => true,
        [CONSENT_SUCCESS]: () => false,
        [CONSENT_FAILURE]: () => false,
      },
      false,
    ),
    challenge: handleActions(
      {
        [SET_CHALLENGE]: (_, action) => action.challenge,
      },
      null,
    ),
    authCodeUuid: handleActions(
      {
        [AUTHENTICATE_REQUEST_FOR_OOB]: (_, action) => action.payload.json,
        [SET_AUTH_CODE_UUID]: (_, action) => action.authCodeUuid,
      },
      null,
    ),
    otpRetryTimer: handleAction(SET_OTP_RETRY_TIMER, (_, action) => action.payload, null),
  }),
};
